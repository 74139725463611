import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";

export const getUserNotifications = createAsyncThunk(
  "user/getUserNotifications",
  async (payload) => {
    return apiGet(API_URL.getUserNotifications)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getCourseCertificate = createAsyncThunk(
  "user/getCourseCertificate",
  async (payload) => {
    return apiGet(`${API_URL.getCourseCertificate}/${payload.courseId}`,null,null, false, "error")
      .then((res) => {
        if(payload.callBack){
           payload.callBack();
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const markMessageAsRead = createAsyncThunk(
  "user/markMessageAsRead",
  async (payload, {dispatch}) => {
    return apiPost(API_URL.markMessageAsRead,payload,null,true)
      .then((res) => {
        dispatch(getUserNotifications())
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getSafetichaAmbassadors = createAsyncThunk(
  "user/getSafetichaAmbassadors",
  async (payload) => {
    return apiGet(API_URL.getSafetichaAmbassadors,null,null, true)
      .then((res) => {
        
        console.log('res,data', res.data)
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);
