import React, { useState } from "react";
import styles from "../modal.module.scss";
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import AppInput from "../../inputs/AppInput";
import { formatAsCurrency } from "../../../utils/numberFunctions";
import { textIsDigits, textIsEmail } from "../../../utils/stringFunctions";
import { showSimpleToast } from "../../../utils/alertFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getUrlForGiftSafeticha } from "../../../redux/cart/actions";

const GiveSafeticha = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const {isLoading} = useSelector(state => state.cartReducer)

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);

  const onInputChange = (e) => {
    const input = e.target.value;
    if (textIsDigits(input) || !input.length) {
      setAmount(input)
    }
  }

  const onEmailInputChange = (e) =>{ 
    setEmail(e.target.value)
  }

  const onSubmit = () => {
    if(!textIsEmail(email)){
      showSimpleToast("Email provided is not valid.", "failed") 
      return
    }
    if(!termsChecked){
      showSimpleToast("Please check and accept the terms to continue", "failed") 
      return
    }
    if (amount && amount.length) {
      dispatch(getUrlForGiftSafeticha({ data: { amount, email }, callback:() => onClose() }))
    } else {
      showSimpleToast("Please enter an amount to give", "failed")
    }
  }

  return !isOpen ? <></> : (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2>Support Safeticha’s Mission to Empower Teachers</h2>
        <div className={styles.content}>
          <p>
            When you choose to "Give Directly to Safeticha," you are helping us make a lasting impact in the lives of teachers across Nigeria and Africa. Here’s how your contributions will be used:
          </p>
          <p><strong>Quarterly Teacher Giveaways: </strong> Every quarter, we reward hardworking and deserving teachers to appreciate their efforts and encourage excellence, these monies go directly into their wallets.</p>
          <p><strong>Empowering 100,000 Nigerian Teachers in 2025: </strong>Through free community outreaches and masterclasses, we aim to equip educators with the tools to earn additional income.</p>
          <p><strong>Scholarships for Career Advancement: </strong>We plan to sponsor 100 teachers for professional certifications or further degrees to advance their careers.</p>
          <p><strong>Education Events Sponsorships:</strong>Safeticha will participate in major education events in Nigeria, ensuring we sponsor at least 10 educators to attend these prestigious events.</p>
          <p><strong></strong></p>
          <Box>
            <AppInput label="Amount to give" onChange={onInputChange} 
              value={amount} helperText={amount && amount.length ? `You are giving ${formatAsCurrency(amount, "₦")}` : null} />
              <AppInput label="Email" onChange={onEmailInputChange} 
              value={email} />
                      <FormGroup sx={{mb:"30px"}}>
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          checked={termsChecked}
                                                          onChange={(e)=>setTermsChecked(e.target.checked)}
                                                          inputProps={{ "aria-label": "controlled" }}
                                                      />
                                                  }
                                                  label={
                                                      <span>
                                                        By choosing to give to Safeticha, you agree for Safeticha to use these funds for its monthly teacher reward programs, free teacher training, and teacher scholarships/sponsorships. Annual report of funds will be published to stakeholders.
                                                         
                                                      </span>
                                                  }
                                              />
                                          </FormGroup>
            <Button onClick={onSubmit} disabled={isLoading} sx={{
              backgroundColor: "#2E3192", color: "#fff", fontSize: "16px", px: "10px", py: "5px", mr: "10px", borderRadius: "10px", "&:hover": {
                backgroundColor: "rgb(23, 29, 209)",
                color: "#fff",
              },
            }}>{isLoading ? "Processing...":"Donate"}</Button>
            <Button disabled={isLoading} sx={{
              backgroundColor: "#fff", color: "#2E3192", fontSize: "16px", px: "10px", py: "5px", borderRadius: "10px", "&:hover": {
                backgroundColor: "rgba(37, 39, 108, 0.29)",
                color: "#2E3192",
              },
            }} onClick={onClose}>Close</Button>

          </Box>
        </div>
      </div>
    </div>
  );
};

export default GiveSafeticha;
