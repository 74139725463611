import React from "react";
import styles from "./modal.module.scss";
import Iframe from "react-iframe";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WatchTeacherYoutube = ({ isOpen, onClose, teacher }) => {

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };
  const navigate = useNavigate()

  if (!isOpen) return null;
  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div style={{marginTop:"30px"}}></div>
        <div className={styles.content}>
            <Box height="400px" width="100%">
                <Iframe
          url={`${teacher?.videoUrl}?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&origin=http://youtubeembedcode.com`}
          width="100%"
          height="100%"
          id="ss"
          className=""
          display="block"
          position="relative"
        />   
            </Box>
     
          <div className={styles.footer}>
          <Button onClick={() => navigate(`/store/${teacher?.user?.store?.path}`)} sx={{
              backgroundColor: "#2E3192", color: "#fff", fontSize: "16px", px: "10px", py: "5px", mr: "10px", borderRadius: "10px", "&:hover": {
                backgroundColor: "rgb(23, 29, 209)",
                color: "#fff",
              },
            }}>Go to store</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchTeacherYoutube;
