import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPost } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";
import { showSimpleToast } from "../../utils/alertFunctions";

export const getUrlForGiftSafeticha = createAsyncThunk(
    "cart/getUrlForGiftSafeticha",
    async (payload, { dispatch }) => {
        return apiPost(API_URL.giftSafeticha, payload.data)
            .then((res) => {
                if(payload.callback){
                    payload.callback();
                }
                if (res.data?.data?.reference == "_") {
                    showSimpleToast("Gifting...", "success");
                } else {
                    window.location.href = res.data?.data?.paymenturl;
                }
                return res.data;
            })
            .catch((error) => {
                return error;
            });
    }
);