import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost, apiPut, apiDelete } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";
import { getUserProfile } from "../dashboard/actions";
import { showSimpleToast } from "../../utils/alertFunctions";

export const createResource = createAsyncThunk(
  "resource/createResource",
  async (payload) => {
    return apiPost(API_URL.createResource, payload.data)
      .then((res) => {
        if (payload.callBack) {
          console.log('res----', res)
          payload.callBack(res.data.data.uploadUrl);
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);
export const editResource = createAsyncThunk(
  "resource/editResource",
  async (payload) => {
    return apiPut(`${API_URL.editResource}/${payload.data.id}`, payload.data)
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);
export const deleteResource = createAsyncThunk(
  "resource/deleteResource",
  async (payload) => {

    return apiDelete(`${API_URL.deleteResource}/${payload.id}`)
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);


export const getAllMarketPlaceResources = createAsyncThunk(
  "resource/getAllMarketPlaceResources",
  async (payload) => {
    return apiGet(API_URL.getAllMarketPlaceResources,payload.data)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getAllMyResources = createAsyncThunk(
  "resource/geAllMyResources",
  async (payload) => {
    return apiGet(API_URL.getAllResources,payload?.data)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getMyResourcesSingle = createAsyncThunk(
  "resource/getMyResourcesSingle",
  async (payload) => {
    console.log(payload.data)
    return apiGet(`${API_URL.getAllResources}/user/${payload.data?.userId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getMyResourceBalances = createAsyncThunk(
  "resource/getMyResourceBalances",
  async (payload) => {
    return apiGet(API_URL.getMyResourceBalances)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getResourceById = createAsyncThunk(
  "resource/getResourceById",
  async (payload) => {
    return apiGet(`${API_URL.getAllResources}/${payload.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const purchaseResource = createAsyncThunk(
  "resource/purchaseResource",
  async (payload, {dispatch}) => {
    return apiPost(API_URL.purchaseResoure, payload.data)
      .then((res) => {
        if(res.data?.data?.reference == "_"){
          showSimpleToast("Transaction initiated", "success");
          // dispatch(getMyCourses());
        }else{
           window.location.href = res.data?.data?.paymenturl;
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

// export const updateJob = createAsyncThunk(
//   "jobs/updateJob",
//   async (payload) => {
//     return apiPut(`${API_URL.updateJob}/${payload.id}`, payload.data)
//       .then((res) => {
//         if (payload.callBack) {
//           payload.callBack();
//         }

//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );



// export const getJobDetails = createAsyncThunk(
//   "jobs/geJobDetails",
//   async (payload) => {
//     return apiGet(`${API_URL.job}/${payload.jobId}`)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const getJobByCode = createAsyncThunk(
//   "jobs/getJobByCode",
//   async (payload) => {
//     return apiGet(`${API_URL.getJobByCode}/${payload.code}`)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const updateJobDetails = createAsyncThunk(
//   "jobs/updateJobDetails",
//   async (payload) => {
//     return apiPut(`${API_URL.job}/${payload.jobId}`, payload.data)
//       .then((res) => {
//         if (payload.callBack) {
//           payload.callBack();
//         }
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const getAvailableJobs = createAsyncThunk(
//   "jobs/getAvailableJobs",
//   async (payload) => {
//     return apiGet(API_URL.job)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const applyForJob = createAsyncThunk(
//   "jobs/applyForJob",
//   async (payload, { dispatch }) => {
//     return apiPost(`${API_URL.applyForJob}/${payload.jobId}`, {})
//       .then((res) => {
//         dispatch(getJobDetails(payload))
//         if (payload.callBack) {
//           payload.callBack();
//         }

//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const updateApplicationStatus = createAsyncThunk(
//   "jobs/updateApplicationStatus",
//   async (payload, { dispatch }) => {
//     return apiPost(`${API_URL.updateApplicationStatus}/${payload.applicationId}`, payload.statusInfo)
//       .then((res) => {
//         // dispatch(getJobDetails(payload))
//         if (payload.callback) {
//           payload.callback(dispatch);
//         }

//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const sendChatForJobApplication = createAsyncThunk(
//   "jobs/sendChatForJobApplication",
//   async (payload, { dispatch }) => {
//     return apiPost(`${API_URL.sendChatForJobApplication}/${payload.applicationId}`, payload.messageInfo, null, true)
//       .then((res) => {
//         if (payload.callback) {
//           payload.callback(dispatch);
//         }

//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const getChatForJobApplication = createAsyncThunk(
//   "jobs/getChatForJobApplication",
//   async (payload, { dispatch }) => {
//     return apiPost(`${API_URL.getChatForJobApplication}/${payload.applicationId}`, payload.userInfo, null, true)
//       .then((res) => {
//         if (payload.callback) {
//           payload.callback(dispatch);
//         }

//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const getChatForJobApplicationLowKey = createAsyncThunk(
//   "jobs/getChatForJobApplicationLowKey",
//   async (payload, { dispatch }) => {
//     return apiPost(`${API_URL.getChatForJobApplication}/${payload.applicationId}`, payload.userInfo, null, true)
//       .then((res) => {
//         if (payload.callback) {
//           payload.callback(dispatch);
//         }

//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );
