import React from "react";
import styles from "../modal.module.scss";

const Purpose = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2>Welcome to Safeticha’s "Gift-A-Teacher for Christmas" Initiative!</h2>
        <div className={styles.content}>
          <p>
          This holiday season, join us in empowering educators by supporting their hard work and dedication. Through this initiative, you can make a direct impact by purchasing teaching resources created by our featured teacher ambassadors. Every purchase goes straight into the teacher's wallet, ensuring they receive 100% of the proceeds.
 
          </p>

        <p>Want to explore more teachers beyond our ambassadors? Simply scroll down and click the "View All Teachers" button to access profiles and stores of all educators on the platform.</p>

        <p>If you'd prefer to make a lump sum donation, click "Give Directly to Safeticha" at the bottom of this page. Your contributions will support Safeticha’s ongoing programs, including teacher rewards, free training, and scholarships. Full details on how we use these funds are available on the donation page.</p>
          <p><strong>Together, let’s celebrate and uplift the educators who shape our future!</strong></p>
          <div className={styles.footer}>
            <button className={styles.footerCloseButton} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purpose;
