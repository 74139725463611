import React from 'react';
import styles from './CourseCard.module.css'; 
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Rating from '../../../landing-page/Resources/Rating/Rating';
import { formatAsCurrency } from '../../../../utils/numberFunctions';
import AltResource from '../../../../assets/img/education.png'
import {useNavigate } from 'react-router-dom';

// Custom card component that receives props for displaying individual course information
const CourseCard = ({ id ,title, rating, price, img }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.cardContainer} onClick={() => navigate(`/marketplace/${id}`)}>
      <img src={img || AltResource} alt={title} className={styles.cardImage} />
      <div className={styles.cardContent}>
        <h3>{title}</h3>
        <div className={`${styles.rating_section}`}>
           <div className={`${styles.timeRated}`}>{formatAsCurrency(price, "₦")}</div>
          <Rating rating={5} />

        </div>
      </div>
    </div>
  );
};

export default CourseCard;
