// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bVLG96as28waPwxza5xQ{position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.7);display:flex;justify-content:center;align-items:center;backdrop-filter:blur(5px);z-index:1000}.Xb60mpUJTP4Qvb9srv8D{background:#fff;border-radius:10px;width:90%;max-width:700px;padding:20px;max-height:80vh;overflow-y:auto;box-shadow:0 4px 8px rgba(0,0,0,.1);position:relative}.EfzGf0pvUKKbVvgxMtWy{position:absolute;top:15px;right:15px;background:none;border:none;font-size:30px;cursor:pointer}.uUesGU9n9tXZFulvFVJQ{margin-top:20px;line-height:1.6;color:#333}.uUesGU9n9tXZFulvFVJQ h2,.uUesGU9n9tXZFulvFVJQ h3{color:#0056b3;margin-bottom:10px}.uUesGU9n9tXZFulvFVJQ p{margin-bottom:15px}.uUesGU9n9tXZFulvFVJQ ul li{list-style-type:disc !important;margin-left:20px}.aJs_wUBVNHH3iKFKK45l{display:flex;justify-content:center;padding:20px 0}.sB6iK5vg1p4MeBTtzpaA{background-color:#0056b3;color:#fff;border:none;padding:10px 20px;font-size:16px;border-radius:5px;cursor:pointer;transition:background-color .3s ease}.sB6iK5vg1p4MeBTtzpaA:hover{background-color:#003e82}`, "",{"version":3,"sources":["webpack://./src/components/marketing/modal.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,yBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,YAAA,CAIF,sBACE,eAAA,CACA,kBAAA,CACA,SAAA,CACA,eAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CACA,mCAAA,CACA,iBAAA,CAIF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CAIF,sBACE,eAAA,CACA,eAAA,CACA,UAAA,CAEA,kDACI,aAAA,CACA,kBAAA,CAGF,wBACE,kBAAA,CAGF,4BACE,+BAAA,CACA,gBAAA,CAKR,sBACI,YAAA,CACA,sBAAA,CACA,cAAA,CAGF,sBACE,wBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CACA,oCAAA,CAEA,4BACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `bVLG96as28waPwxza5xQ`,
	"modal": `Xb60mpUJTP4Qvb9srv8D`,
	"closeButton": `EfzGf0pvUKKbVvgxMtWy`,
	"content": `uUesGU9n9tXZFulvFVJQ`,
	"footer": `aJs_wUBVNHH3iKFKK45l`,
	"footerCloseButton": `sB6iK5vg1p4MeBTtzpaA`
};
export default ___CSS_LOADER_EXPORT___;
