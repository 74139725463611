import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, Typography } from "@mui/material";
import { APP_COLOURS } from "../../utils/constant";

const AppInput = ({
  variant,
  value,
  name,
  onChange,
  label,
  placeholder,
  fullWidth,
  noBottomPad,
  bgColor,
  customStyles,
  disableOutline,
  error,
  helperText,
  required,
  type,
  inputProps,
  inputStyles,
  disabled,
  multiline,
  rows, minRows, maxRows, ...params
}) => {
  return (
    <Box sx={{ ...styles.box(noBottomPad), ...inputStyles }}>
      {label && <Typography sx={styles.inputText}>{label}</Typography>}
      <TextField
      {...params}
        variant={variant}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        error={error}
        fullWidth={fullWidth}
        required={required}
        type={type}
        inputProps={inputProps}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        sx={{
          ...styles.inputBg(bgColor),
          ...customStyles,
          ...styles.testing(disableOutline),
        }}
      />
      {error && (
        <Typography component="em" color="error" mt="15px" fontSize="14px">
          {helperText}
        </Typography>
      )}
      {!error && helperText && (
        <Typography component="em" color="primary" mt="15px" fontSize="14px">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  box: (noBottomPad) => {
    return { marginBottom: noBottomPad ? "0px" : "24px" };
  },
  inputText: { color: APP_COLOURS.lightDark, marginBottom: 1 },
  inputBg: (bgColor) => {
    return { bgcolor: bgColor };
  },
  testing: (disableOutline) => {
    if (disableOutline) {
      return { "& fieldset": { border: "none" } };
    }
  },
};

AppInput.propTypes = {
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

AppInput.defaultProps = {
  type: "text",
  variant: "outlined",
  label: "",
  fullWidth: true,
  onChange: () => {},
};

export default AppInput;
