import { getMyResourceBalances } from "../redux/resource/action";

export const API_URL = {
  // base_url: "https://0a22-102-88-110-29.ngrok-free.app/v1",
  // client_url: "http://localhost:8080",
  base_url: "https://api.safeticha.com/v1",
  client_url: "https://safeticha.com",
  registerTeacher: "/auth/register/teacher",
  registerSchool: "/auth/register/school", 
  verify: "/auth/verify-email",
  login: "/auth/login",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  checkEmail: "/auth/check-email",
  teacherCategories: "/lookup/category/teacher",
  schoolCategories: "/lookup/category/school",
  qualification: "/lookup/qualification",
  specialisation: "/lookup/specialisation/",
  getProfile: "/user/myprofile",
  getUserProfile: "/user/profile",
  uploadProfileImage: "user/upload-image",
  updateProfileData: "user/update-profile",
  changePassword: "user/change-password",
  getAllCourses: "/course/list",
  getPurchaseUrl: "/course/initiatePurchase", 
  updateCourseProgress: "course/progress",
  getMyCourses: "/course/mine",
  getAllCategoryCourses: "/course/list?categoryId=",
  getAllPopularCourses: "/course/list?&type=popular",
  getCourse: "/course/",
  getCourseCategory: "/course/category",
  getCourseContent: "/course/content",
  getCourseCertificate: "certificate/create",
  uploadProfileDocument: "/document/upload",
  getUserDocuments: "/document",
  getCourseProgress: "/course/progress",
  getModuleQuestions: "/question/courseModule",
  submitModuleQuestions: "question/submitAnswers",
  getSchoolTeachers: "/school/teachers",
  inviteTeacherToSchool: "/school/invite",
  getUserNotifications: "/user/notifications",
  respondToSchoolInvite: "/school/invite-respond",
  listTeachersForCourse: "/course/list-teachers",
  addTeachersToCourse: "/course/add-teachers",
  createJob: "/job/create",
  updateJob: "/job/edit",
  listMyCreatedJobs: "/job/created",
  job: "/job",
  getJobByCode: "/job/code",
  applyForJob: "/job/apply",
  getJobTokens:"/job/tokens",
  updateApplicationStatus: "/job/application/status",
  getjobApplication: "/job/application",
  sendChatForJobApplication: "/job/application/chat",
  getChatForJobApplication: "/job/application/messages",
  buyMoreJobToken: "/job/buyjobtoken",
  markMessageAsRead: "/message/markasread",
  sendVerificationMail: "/auth/send-verification-email",
  createResource: "/resource/create",
  editResource: "/resource",
  deleteResource: "/resource",
  getMyResourceBalances: "/resource/balances",
  getAllMarketPlaceResources: "/resource/marketplace",
  purchaseResoure: "/resource/purchase",
  getAllResources: "/resource",
  giftSafeticha: "/user/giftsafeticha",
  getSafetichaAmbassadors: "/user/ambassadors"
};


