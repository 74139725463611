import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GiftTeacher from "../../assets/img/Marketing/gift_teacher.png";
import ChristmasTree from "../../assets/img/Marketing/christmas_tree.png";
import DanglingBell from "../../assets/img/Marketing/dangling_bell.png";
import CenterSnow from "../../assets/img/Marketing/center_snow.png";
import LeftSnow from "../../assets/img/Marketing/right_snow.png";
import Underline from "../../assets/img/Marketing/underline.png";
import RightSnow from "../../assets/img/Marketing/left_snow.png";
import { Slideshow } from '@mui/icons-material';
import { useScreenDimensions } from '../../hooks/useScreenDimension';
import Navbar from '../../components/landing-page/Navbar/Navbar';
import TeacherGiftCard from '../../components/marketing/TeacherGiftCard';
import Purpose from '../../components/marketing/Purpose/Purpose';
import GiveSafeticha from '../../components/marketing/GiveSafeticha/GiveSafeticha';
import { useDispatch, useSelector } from 'react-redux';
import { getSafetichaAmbassadors } from '../../redux/user/action';
import FullGiftTeacherCard from '../../components/marketing/FullGiftTeacherCard';
import WatchTeacherYoutube from '../../components/marketing/WatchTeacherYoutube';
import { setTeacherToGift } from '../../redux/user/slice';
import GiftTeacherCash from '../../components/marketing/GiftTeacher/GiftTeacherCash';

export default function GiftATeacher() {
    const { width } = useScreenDimensions();
    const dispatch = useDispatch();
    const [showPurpose, setShowPurpose] = useState(false);
    const [showGiveSafeticha, setShowGiveSafeticha] = useState(false);
    const [showFullCard, setShowFullCard] = useState(null);
    const [showVideo, setShowVideo] = useState(null);
    const {safetichaAmbassadors, loadingAmb, teacherToGift} = useSelector(state => state.userReducer)
    useEffect(() =>{
        dispatch(getSafetichaAmbassadors());
    }, [dispatch]);
    return (
        <>
            <Navbar />
            <Purpose isOpen={showPurpose} onClose={()=> setShowPurpose(false)} />
            <GiveSafeticha isOpen={showGiveSafeticha} onClose={()=> setShowGiveSafeticha(false)} />
            <GiftTeacherCash isOpen={teacherToGift != null} onClose={()=> dispatch(setTeacherToGift(null))} />
            <FullGiftTeacherCard isOpen={showFullCard != null} onClose={()=> setShowFullCard(null)} teacher={showFullCard} />
            <WatchTeacherYoutube isOpen={showVideo != null} onClose={()=> setShowVideo(null)} teacher={showVideo} />
            <Box sx={{ paddingBottom: { xs: "40px", md: "70px" } }} width="100%" bgcolor="#2E6FB6" position="relative" >

                <Box component="img" src={LeftSnow} position="absolute" sx={{ display: { xs: "none", md: "block" } }} />
                <Box component="img" src={CenterSnow} position="absolute" sx={{ left: { xs: 0, md: "30%" }, width: { xs: "90%", md: "auto" } }} />
                <Box component="img" src={RightSnow} position="absolute" sx={{ display: { xs: "none", md: "block" } }} right={0} />

                <Box component="img" src={ChristmasTree} sx={{ display: { xs: "none", md: "block" } }} position="absolute" bottom={0} left="30px" />
                <Box component="img" src={DanglingBell} sx={{ width: { xs: "80px", md: "auto" } }} position="absolute" right={2} />


                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, width: { xs: "80%", md: "50%", } }} paddingTop="50px" marginLeft="auto" marginRight="auto">
                    <Box component="img" sx={{ position: { md: "absolute" }, height: { xs: "70px", md: "120px" }, width: { xs: "65px", md: "110px" }, ml: { xs: "auto" }, mr: { xs: "auto" } }} src={GiftTeacher} />
                    <Box textAlign="center" sx={{ mt: { xs: "15px", md: "50px" } }}>
                        <Typography color="#fff" sx={{ fontSize: { md: "50px", xs: "30px" } }} fontWeight="bold" mb="10px">Gift-A-Teacher</Typography>
                        <Typography color="#fff" sx={{ fontSize: { md: "50px", xs: "30px" } }} fontWeight="bold" mb="10px">This Christmas</Typography>
                        <Typography color="#fff" sx={{ fontSize: { md: "16px", xs: "12px" } }}>This Christmas, join Safeticha to support hardworking teachers by purchasing their resources or funding their career growth through scholarships and free training. Together, we can empower 100,000 Nigerian educators to thrive in 2025.</Typography>
                        <Button startIcon={<Slideshow />} sx={{
                            backgroundColor: "#fff", color: "#000", px: "30px", fontSize: "18px", marginTop: "40px", borderRadius: "10px", "&:hover": {
                                backgroundColor: "#2E6FB6",
                                border: "solid 1px #fff",
                                color: "#fff",
                            },
                        }} onClick={()=> setShowPurpose(true)}>Read our purpose </Button>
                    </Box>
                </Box>

                {/* <Box component="img" src={HeroVideo} position="absolute" sx={{ height: { md: "280px" }, width: { xs: "80%", md: "510px" }, left: { md: "calc(50% - 255px)", xs: "10%" }, top: { md: "85%", xs: "90%" } }} right="auto" /> */}
            </Box>

            <Box px="5%" sx={{mt:"20px"}}>
                <Box display="flex" alignItems="center" flexDirection="column" width="100%" sx={{ mt: { md: "50px", xs: "20px" } }}>
                    <Typography sx={{ fontSize: { md: "30px", xs: "18px" }, fontWeight: "500" }}>Explore & Start gifting now</Typography>
                    <Box component="img" src={Underline} mt="15px" />
                </Box>
                <Box sx={{mt:{md:"50px",xs:"30px"}}} display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                    <Typography sx={{fontWeight:"bold", fontSize:{xs: "20px",md:"35px"}}}>Our <span style={{color:"#FF5630"}}>Ambassadors</span></Typography>
                    <Button sx={{
                            backgroundColor: "#2E3192", color: "#fff", px: "30px", fontSize: "18px", borderRadius: "20px", "&:hover": {
                                backgroundColor: "#fff",
                                border: "solid 1px #2E3192",
                                color: "#2E3192",
                            },
                        }} onClick={()=> setShowGiveSafeticha(true)}>Donate</Button>
                </Box>
                <Grid container spacing={3} mb={3}>
                    {loadingAmb && <CircularProgress sx={{textAlign:"center", mt:"30px"}} />}
                    {!loadingAmb && safetichaAmbassadors.map(ambassador => <Grid item xs={12} md={3} >
                        <TeacherGiftCard teacher={ambassador} onSeeMore={(x)=>setShowFullCard(x)} onShowVideo={(x)=>setShowVideo(x)}   />
                    </Grid>)}
                </Grid>

            </Box>
        </>




    )
}
