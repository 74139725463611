import { createSlice } from "@reduxjs/toolkit";
import { getUrlForGiftSafeticha } from "./actions";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartCourses: [],
    courseExists: false,
    isLoading: false
  },
  reducers: {
    // ADD COURSE TO CART
    addCourseToCart: (state, action) => {
      const newCourse = action.payload;

      const existingCourse = state.cartCourses.some(
        (course) => course.id === newCourse.id
      );

      if (!existingCourse) {
        state.cartCourses.push({
          id: newCourse.id,
          name: newCourse.name,
          coverImage: newCourse.coverImage,
          contentInfo: newCourse.contentInfo,
          category: newCourse.category,
          price: newCourse.price,
          units: newCourse.units
        });
      }
    },
    setCourseCart: (state, {payload}) => {
      state.cartCourses = payload;
    },
    // REMOVE COURSE FROM CART
    removeCourseFromCart: (state, action) => {
      const id = action.payload;

      const existingCourse = state.cartCourses.some(
        (course) => course.id === id
      );

      if (existingCourse) {
        state.cartCourses = state.cartCourses.filter(
          (course) => course.id !== id
        );
      }
    },

    // CHECK COURSE EXISTS
    checkCourseExists: (state, action) => {
      const id = action.payload;

      const existingCourse = state.cartCourses.some(
        (course) => course.id === id
      );

      if (existingCourse) {
        state.courseExists = true;
      } else {
        state.courseExists = false;
      }
    },

    // CLEAR CART
    clearCart: (state, payload) => {
      state.cartCourses = [];
    },
  },
  extraReducers: {
       // ---------GET PURCHASE URL ---------/
        [getUrlForGiftSafeticha.pending]: (state) => {
          state.isLoading = true;
        },
        [getUrlForGiftSafeticha.fulfilled]: (state, { payload }) => {
          if (payload?.status === "success") {
        
        
          }
          state.isLoading = false;
        },
        [getUrlForGiftSafeticha.rejected]: (state) => { 
          state.isLoading = false;
        },
  },
});

export const {
  addCourseToCart,
  removeCourseFromCart,
  clearCart,
  checkCourseExists,
  setCourseCart
} = cartSlice.actions;

export default cartSlice.reducer;
