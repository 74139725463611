import React, { useEffect, useState } from "react";
import styles from "../modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress, FormGroup } from "@mui/material";
import AppInput from "../../inputs/AppInput";
import { textIsDigits, textIsEmail, truncateString } from "../../../utils/stringFunctions";
import { showSimpleToast } from "../../../utils/alertFunctions";
import { formatAsCurrency } from "../../../utils/numberFunctions";
import { getAllMyResources, getMyResourcesSingle } from "../../../redux/resource/action";
import { getUrlForGiftSafeticha } from "../../../redux/cart/actions";

const GiftTeacherCash = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const {teacherToGift} = useSelector(state => state.userReducer)
  const {userResource, isFetchingResources} = useSelector(state => state.resourceReducer)
    const [amount, setAmount] = useState("");
      const [email, setEmail] = useState("");
      const [isLoading, setTermsChecked] = useState(false);
    
      const onInputChange = (e) => {
        const input = e.target.value;
        if (textIsDigits(input) || !input.length) {
          setAmount(input)
        }
      }
    
      const onEmailInputChange = (e) =>{ 
        setEmail(e.target.value)
      }
    
      const onSubmit = () => {
        if(!textIsEmail(email)){
          showSimpleToast("Email provided is not valid.", "failed") 
          return
        }
        if (amount && amount.length) {
          if(parseInt(amount) < userResource.price){
            showSimpleToast("The gift amount must equal or be greater than the resource price - ₦"+ formatAsCurrency(userResource.price) , "failed")
            return;
          }
          dispatch(getUrlForGiftSafeticha({ data: { amount, email, userId: teacherToGift?.user?.id, teacherId: teacherToGift?.id, gift :"teacher", resourceId: userResource?.id }, callback:() => onClose() }))
        } else {
          showSimpleToast("Please enter an amount to give", "failed")
        }
      }
      useEffect(() =>{
        if(teacherToGift && teacherToGift?.user?.id){
           dispatch(getMyResourcesSingle({data: {userId: teacherToGift?.user?.id}}))
        }
      }, [dispatch, teacherToGift, teacherToGift?.user?.id])
      
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2>Gift-A-Teacher for Christmas ❤️</h2>
        <div className={styles.content}>
          {/* <Box>
            <Box
          </Box> */}
          <p>
          Appreciate <b>{`${teacherToGift.firstName} ${teacherToGift.lastName}`}</b> by gifting this holiday season, gift a teacher by buying their resource in a desired amount. 
          </p>
          <i > Please note that this will be funded into {`${teacherToGift.firstName} ${teacherToGift.lastName}'s`} profile on Safeticha to use as pleased</i>

{!isFetchingResources && userResource && <Box display="flex" flexDirection="row" mt="20px">
<Box component="img" src={userResource.coverImage || "https://cdn-icons-png.flaticon.com/512/5833/5833290.png"} sx={{width: "150px", height:"150px" , marginRight:"15px"}} />
<Box>
  <b>{userResource.title}</b>
<p>{truncateString(userResource.description, 100)}</p>
  </Box>
</Box>}
{isFetchingResources &&<Box mt="20px">
  <CircularProgress  />
</Box> }
          <Box mt={2}>
            <AppInput label="Purchase price" onChange={onInputChange} 
              value={amount} helperText={amount && amount.length ? `You are giving ${formatAsCurrency(amount, "₦")}` : null} />
              <AppInput label="Email" onChange={onEmailInputChange} 
              value={email} />
                      {/* <FormGroup sx={{mb:"30px"}}>
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          checked={termsChecked}
                                                          onChange={(e)=>setTermsChecked(e.target.checked)}
                                                          inputProps={{ "aria-label": "controlled" }}
                                                      />
                                                  }
                                                  label={
                                                      <span>
                                                        By choosing to give to Safeticha, you agree for Safeticha to use these funds for its monthly teacher reward programs, free teacher training, and teacher scholarships/sponsorships. Annual report of funds will be published to stakeholders.
                                                         
                                                      </span>
                                                  }
                                              />
                                          </FormGroup> */}
            <Button onClick={onSubmit} disabled={isLoading} sx={{
              backgroundColor: "#2E3192", color: "#fff", fontSize: "16px", px: "10px", py: "5px", mr: "10px", borderRadius: "10px", "&:hover": {
                backgroundColor: "rgb(23, 29, 209)",
                color: "#fff",
              },
            }}>{isLoading ? "Processing...":"Purchase & Gift"}</Button>
            <Button disabled={isLoading} sx={{
              backgroundColor: "#fff", color: "#2E3192", fontSize: "16px", px: "10px", py: "5px", borderRadius: "10px", "&:hover": {
                backgroundColor: "rgba(37, 39, 108, 0.29)",
                color: "#2E3192",
              },
            }} onClick={onClose}>Close</Button>

          </Box>

        </div>
      </div>
    </div>
  );
};

export default GiftTeacherCash;
