import { useLocation } from 'react-router-dom';

export const useQueryParam = (paramName) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  return params.get(paramName);
};
export const textIsEmail = value => {
  const pattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return pattern.test(value);
};

export const textIsDigits = (value, removeCommaFirst = false) => {
  let input = value;
  if (removeCommaFirst) {
    input = removeAllCommas(input);
  }
  const pattern = /^\d+$/;
  return pattern.test(input);
};

export function removeAllSpaces(input) {
  return input ? input.replace(/\s/g, '') : '';
}

export function removeAllCommas(input) {
  if (input) {
    var str = `${input}`;
    return str.replace(/,/g, '');
  }
  return input;
}

export function decodeHTML(input) {
  return input ? input.replace(/&lt;/g, '<') : '';
}

export const getStatusText = (status) => {
  if(status == 0) return "Open";
  if(status == 1) return "Accepted"
  if(status == 2) return "Rejected"
  if(status == 3) return "Completed"
  if(status == 4) return "Closed"
  else return ""
}

export const getStatusTextColor = (status) => {
  if(status == 0) return "";
  if(status == 1) return "primary"
  if(status == 2) return "error"
  if(status == 3) return "success"
  if(status == 4) return "error"
}

export const getStatusKey = (status) => {
  if (status) {
    if (status == 1) return "accept";
    if (status == 2) return "reject";
    if (status == 3) return "complete";
    if (status == 4) return "close";
  }
};

export function truncateString(
  text,
  takeLength,
  prefix = '...',
  startIndex = 0,
  endIndex,
) {
  if (!text?.length) {
    return '';
  }
  if (text.length <= takeLength) {
    return text;
  } else if (endIndex) {
    var sub = text.substring(startIndex, takeLength);
    var subEnd = text.substring(endIndex);
    sub += prefix;
    return sub + subEnd;
  } else {
    var sub = text.substring(startIndex, takeLength);
    sub += prefix;
    return sub;
  }
}