import { Box, Button, Card, CardMedia, Stack, Typography } from '@mui/material'
import React from 'react'
import Gift from "../../assets/img//Marketing/gift.png";
import { Slideshow } from '@mui/icons-material';
import { truncateString } from '../../utils/stringFunctions';
import { useNavigate } from 'react-router-dom';
import DashboardAvatar from "../../assets/img/DashboardAvatar.png";
import { useDispatch } from 'react-redux';
import { setTeacherToGift } from '../../redux/user/slice';

export default function TeacherGiftCard({ teacher, isFull, onSeeMore , onShowVideo}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Card sx={{ mt: "40px", borderRadius: "20px", position: "relative"}}  >
      <Box component="button"  position="absolute" onClick={() => dispatch(setTeacherToGift(teacher))} sx={{ width: "45px", cursor:"pointer", height: "45px", backgroundColor: "#fff", borderRadius: "50%", border: "none", right: "15px", top: "15px" }} display="flex" alignItems="center" justifyContent="center">
        <Box component="img" src={Gift} width="30px" />
      </Box>
      
      <CardMedia
        component="img"
        alt="Name"
        src={teacher?.user?.imgUrl || DashboardAvatar}
        height={isFull ? "390px":"290px"}
        sx={{ '&.MuiCardMedia-root': {
          objectFit: 'cover', // Ensures the image covers the area
          objectPosition: 'center', // Centers the image
        },}}
      />
      <Box px="16px" display="flex" sx={{ alignItems: "center", flexDirection: "column" }}>
        <Typography sx={{ fontSize: "18px", fontWeight: "500", mt: "16px" }}>{teacher?.firstName} {teacher?.lastName}</Typography>
        <Box height={isFull ? "auto": "60px"}>
           <p style={{ textAlign: "center", fontSize: "12px" }}>{isFull ? (teacher?.user?.bio || "") : truncateString(teacher?.user?.bio || "", 100, "...")} {(teacher?.user?.bio || "").length > 100 && !isFull && <span onClick={()=>onSeeMore(teacher)} style={{ color: "#2E3192", fontStyle: "underline", fontWeight: "500", cursor: "pointer" }}>See more</span>} </p>
        </Box>
        <Box mt="15px" mb="20px">
          <Button sx={{
            backgroundColor: "#2E3192", color: "#fff", fontSize: "16px", px: "10px", py: "5px", borderRadius: "10px", "&:hover": {
              backgroundColor: "rgb(23, 29, 209)",
              color: "#fff",
            },
          }} onClick={() => navigate(`/store/${teacher?.user?.store?.path}`)}>Go to store</Button>
          <Button startIcon={<Slideshow />} sx={{
            backgroundColor: "#fff", ml: "10px", color: "#2E3192", fontSize: "16px", px: "10px", py: "5px", borderRadius: "10px", border: "1px solid #2E3192", "&:hover": {
              backgroundColor: "rgba(37, 39, 108, 0.29)",
              color: "#2E3192",
            },
          }} onClick={()=> onShowVideo(teacher)}>About me </Button>
        </Box>
      </Box>
      {/* <Stack direction="row" alignItems="center" spacing={3} p={2} useFlexGap>
      <Stack direction="column" spacing={0.5} useFlexGap>
        <Typography>Kayode Mauel</Typography>
        <Stack direction="row" spacing={1} useFlexGap>
       
        </Stack>
      </Stack>
    </Stack> */}
    </Card>
  )
}
