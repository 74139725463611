import { createSlice } from "@reduxjs/toolkit";
import { getCourseCertificate, getSafetichaAmbassadors, getUserDocuments, getUserNotifications } from "./action";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        userNotifications: [],
        isFetching: false,
        isFetchingCert: false,
        certificates: [],
        certificate: null,
        document: null,
        loadingAmb: false,
        safetichaAmbassadors: [],
        teacherToGift: null
    },
    reducers: {
        setTeacherToGift: (state, {payload}) => {
            state.teacherToGift = payload;
          },
    },
    extraReducers: {
        // ---------GET USER  NOTIFICATIONS ---------/
        [getUserNotifications.pending]: (state) => {
            state.isFetching = true;
        },
        [getUserNotifications.fulfilled]: (state, { payload }) => {
            if (payload.status === "success") {
                state.userNotifications = payload.data;
            }
            state.isFetching = false;
        },
        [getUserNotifications.rejected]: (state, load) => {
            state.isFetching = false
        },
        // ---------GET CERTIFICATE   ---------/
        [getCourseCertificate.pending]: (state) => {
            state.isFetchingCert = true;
        },
        [getCourseCertificate.fulfilled]: (state, { payload }) => {
            if (payload.status === "success") {
                state.certificate = payload.data;
            }
            state.isFetchingCert = false;
        },
        [getCourseCertificate.rejected]: (state, load) => {
            state.isFetchingCert = false
        },
        // ---------GET SAFETICHA AMBASSADORS   ---------/
        [getSafetichaAmbassadors.pending]: (state) => {
            state.loadingAmb = true;
        },
        [getSafetichaAmbassadors.fulfilled]: (state, { payload }) => {
            if (payload.status === "success") {
                state.safetichaAmbassadors = payload.data;
            }
            state.loadingAmb = false;
        },
        [getSafetichaAmbassadors.rejected]: (state, load) => {
            state.loadingAmb = false
        }
    },
});

export const {setTeacherToGift } = userSlice.actions;

export default userSlice.reducer;
