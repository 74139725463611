import React from "react";
import styles from "./modal.module.scss";
import TeacherGiftCard from "./TeacherGiftCard";

const FullGiftTeacherCard = ({ isOpen, onClose , teacher}) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div style={{marginTop: "30px"}}></div>
     <TeacherGiftCard teacher={teacher} isFull />
      </div>
    </div>
  );
};

export default FullGiftTeacherCard;
