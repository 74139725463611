export const APP_COLOURS = {
  primary: "#2E3192",
  primaryDarker: "#12143a",
  primarylighter: "#777ad4",
  white: "#ffffff",
  dark: "#131418",
  lightDark: "#131418",
  grey: "#9DA6BA",
  lightGrey: "#F5F5F5",
  black: "#000000",
  gold: "#cca349",
};

export const PAGES = {
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  signup: "/signup",
  signupVerification: "/signup-verification",
  emailVerification: "/email-verification",
  dashboard: "/dashboard",
  courses: "/courses",
  myCourses: "/courses/my-courses",
  jobs: "/jobs",
  teachers: "/teachers",
  profile: "/profile",
  documents: "/documents",
  messages: "/jobs/messages",
  help: "/help",
  settings: "/settings",
  cart: "/cart",
  checkout: "/checkout",
  changePassword: "/profile/change-password",
  question: "/questions",
  resources: "/resources",
  comingSoon: "/coming-soon",
  addResource: "/resources/add",
  editResource: "/resources/:id/edit",
  notifications: "/notifications",
  manageCourse: "/courses/manage-course",
  certificate: "/certificate/course",
};


export const NOTIFICATION_TYPE = {
  invite: "invite",
  jobMessage: "jobMessage"
}

export const MESSAGE_PURPOSE_TYPE = {
  vacancy: "vacancy",
  jobApplication:"application"
}

export const JOB_ROLES = [
  "Principal",
  "Vice Principal",
  "Head of School",
  "Dean of Students",
  "Guidance Counselor",
  "School Administrator",
  "Registrar",
  "Admissions Officer",
  "School Secretary",
  "Librarian",
  "School Nurse",
  "School Psychologist",
  "Special Education Coordinator",
  "Curriculum Coordinator",
  "Instructional Coach",
  "Teacher Mentor",
  "Student Support Specialist",
  "Attendance Officer",
  "Facilities Manager",
  "Cafeteria Supervisor",
  "Security Officer",
  "Bus Driver",
  "Janitor",
  "IT Support Specialist",
  "Technology Integration Specialist",
  "Media Specialist",
  "Art Teacher",
  "Drama Teacher",
  "Dance Teacher",
  "Physical Education Teacher",
  "Athletic Director",
  "Mathematics Department Head",
  "Science Department Head",
  "English Department Head",
  "Social Studies Department Head",
  "Foreign Language Department Head",
  "Special Education Teacher",
  "Gifted Education Teacher",
  "ESL/ELL Teacher",
  "STEM Coordinator",
  "Career and Technical Education (CTE) Teacher",
  "Music Teacher",
  "Choir Director",
  "Band Director",
  "Orchestra Director",
  "Yearbook Advisor",
  "Student Council Advisor",
  "Community Service Coordinator",
  "EYFS teacher",
  "Teaching assistant",
  "Class teacher",
  "Science teacher",
  "Preschool Teacher",
  "Primary Teacher",
  "Nursery Teacher",
  "Mathematics teacher",
  "English Teacher",
  "Yoruba Teacher",
  "Igbo Teacher",
  "Hausa Teacher",
  "French Teacher",
  "Spanish Teacher",
  "Music Teacher",
  "P.E/Sports Teacher",
  "Government Teacher",
  "Economics Teacher",
  "Chemistry Teacher",
  "Physics Teacher",
  "Biology Teacher",
  "Geography Teacher",
  "English literature teacher",
  "Basic Technology teacher",
  "History teacher",
  "Food & Nutrition Teacher",
  "Design Technology Teacher",
  "Technical Drawing Teacher",
  "Civic Education Teacher",
  "Religious Studies teacher",
  "Commerce Teacher",
  "Further Maths Teacher",
  "Accounting Teacher",
  "Business Studies Teacher",
  "Laboratory Technician",
  "Extracurricular activities coordinator"
];

export const DOCUMENT_TYPE = {
  cv: 'CV',
  policeReport: 'POLICE_REPORT',
  cac: 'CAC_CERTIFICATE',
  nysc: 'NYSC_CERTIFICATE',
  highestDegree: 'HIGHEST_DEGREE',
  profQualification: 'PROFESSIONAL_QUALIFICATION',
  others: 'OTHERS',
};

export const SUBJECTS = [
  { id: 1, name: "Mathematics" },
  { id: 2, name: "Science" },
  { id: 3, name: "English" },
  { id: 4, name: "Social Studies" },
  { id: 5, name: "Foreign Language" },
  { id: 6, name: "Special Education" },
  { id: 7, name: "Gifted Education" },
  { id: 8, name: "ESL/ELL" },
  { id: 9, name: "STEM" },
  { id: 10, name: "Career and Technical Education (CTE)" },
  { id: 11, name: "Music" },
  { id: 12, name: "Choir" },
  { id: 13, name: "Band" },
  { id: 14, name: "Orchestra" },
  { id: 15, name: "Yearbook" },
  { id: 16, name: "Student Council" },
  { id: 17, name: "Community Service" },
  { id: 18, name: "EYFS" },
  { id: 19, name: "Yoruba" },
  { id: 19, name: "Art" },
  { id: 20, name: "Igbo" },
  { id: 21, name: "Hausa" },
  { id: 22, name: "French" },
  { id: 23, name: "Spanish" },
  { id: 24, name: "Music" },
  { id: 25, name: "P.E/Sports" },
  { id: 26, name: "Government" },
  { id: 27, name: "Economics" },
  { id: 28, name: "Chemistry" },
  { id: 29, name: "Physics" },
  { id: 30, name: "Biology" },
  { id: 31, name: "Geography" },
  { id: 32, name: "English Literature" },
  { id: 33, name: "Basic Technology" },
  { id: 34, name: "History" },
  { id: 35, name: "Food & Nutrition" },
  { id: 36, name: "Design Technology" },
  { id: 37, name: "Technical Drawing" },
  { id: 38, name: "Civic Education" },
  { id: 39, name: "Religious Studies" },
  { id: 40, name: "Commerce" },
  { id: 41, name: "Further Maths" },
  { id: 42, name: "Accounting" },
  { id: 43, name: "Business Studies" }
];
