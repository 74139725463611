import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CssBaseline from "@mui/material/CssBaseline";

import PrivateRoute from "./routes/PrivateRoute";

import { PAGES } from "./utils/constant";
import Home from "./pages/website/Home";
import store from "./redux/store";
import Login from "./pages/onboarding/Login";
import ForgotPassword from "./pages/onboarding/ForgotPassword";
import ResetPassword from "./pages/onboarding/ResetPassword";
import LoginVerifyEmail from "./pages/onboarding/LoginVerifyEmail";
import Signup from "./pages/onboarding/Signup";
import SignupVerification from "./pages/onboarding/SignupVerifyEmail";

// dashboard
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardHome from "./pages/dashboard/DashboardHome";
import DashboardCourses from "./pages/dashboard/DashboardCourses";
import DashboardMyCourses from "./pages/dashboard/DashboardMyCourses";
import DashboardTeachers from "./pages/dashboard/DashboardTeachers";
import DashboardProfile from "./pages/dashboard/DashboardProfile";
import DashboardMessages from "./pages/dashboard/DashboardMessages";
import DashboardHelp from "./pages/dashboard/DashboardHelp";
import DashboardSettings from "./pages/dashboard/DashboardSettings";
import DashboardCart from "./pages/dashboard/DashboardCart";

import ChangeProfilePassword from "./pages/dashboard/ChangeProfilePassword";

import CartCheckout from "./pages/dashboard/CartCheckout";
import DashboardCourseDetails from "./pages/dashboard/DashboardCourseDetails";
import DashboardWatchCourse from "./pages/dashboard/DashboardWatchCourse";
import ModuleQuestion from "./pages/question/ModuleQuestion";
import DashboardNotifications from "./pages/dashboard/DashboardNotifications";
import DashboardManageCourse from "./pages/dashboard/DashboardManageCourse";
import ViewCertificate from "./pages/dashboard/ViewCertificate";
import Courses from "./pages/website/Courses";
import BuyCourse from "./pages/website/BuyCourse";
import SummerFest from "./pages/website/SummerFest";
import Resources from "./pages/website/Resources/Resources";
import ResourceDetails from "./pages/website/Resources/ResourceDetails";
import DashboardJobs from "./pages/dashboard/DashboardJobs";
import JobDetails from "./pages/dashboard/JobDetails";
import AddJob from "./pages/job/AddJob";
import JobView from "./pages/website/JobView";
import { Helmet } from "react-helmet";
import ViewApplication from "./components/dashboard/Jobs/ViewApplication";
import BuyJobTokens from "./components/dashboard/Jobs/BuyJobTokens";
import ResourceList from "./pages/resources/ResourceList";
import ComingSoon from "./pages/resources/ComingSoon";
import Documents from "./pages/profile/Documents";
import AddResource from "./pages/resources/AddResource";
import EditResource from "./pages/resources/EditResource";
import { setProfileState } from "./redux/dashboard/slice";
import { setUserState } from "./redux/auth/slice";
import MarketPlace from "./pages/website/Resources/MarketPlace";
import Navbar from "./components/landing-page/Navbar/Navbar";
import { useAuth } from "./utils/auth/authFunctions";
import { Box } from "@mui/material";
import Cart from "./components/dashboard/Cart/Cart";
import ResourcesLandingPage from "./pages/website/Resources/ResourcesLandingPage/ResourcesLandingPage";
import GiftATeacher from "./pages/marketing/GiftATeacher";

//TODO: fix up this messy routing
function AppBase() {
  const dispatch = useDispatch();
  const auth = useAuth(); 
  useEffect(() => {
    dispatch(setProfileState());
    dispatch(setUserState());
  }, [dispatch])
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          href="//db.onlinewebfonts.com/c/6228016f2b172c06410f3a2356d33f6c?family=Roobert-Regular"
          rel="stylesheet"
          type="text/css"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="application-name" content="Safeticha" />
        <meta
          name="keywords"
          content="Safeticha, Safegaurding, Teachers, schools, Edtech app, academy, courses, school recruitment, find jobs, recruit teachers, educators, safe, safety"
        />
        <meta
          name="description"
          content="Safeticha is an online platform to help schools safely recruit and professionally develop educators."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://safeticha.com" />
        <meta property="og:title" content="Safeticha" />
        <meta
          name="og:description"
          content="Safeticha is an online platform to help schools safely recruit and professionally develop educators."
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@safeticha" />
        <meta name="twitter:creator" content="@safeticha" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/assets/safeticha-thumbnail.png" />
        <meta
          property="og:image:alt"
          content="Safeticha - Safegaurding, Recruitment and Professinal Development"
        />
        <meta property="twitter:label1" value="Lean more about safeticha" />
        <meta property="twitter:data1" value="https://safeticha.com" />
        <meta property="twitter:label2" value="Help & Support" />
        <meta property="twitter:data2" value="mailto:princess@safeticha.com" />
        <meta property="og:image:width" content="773" />
        <meta property="og:image:height" content="459" />
        <meta property="og:locale" content="en_US" />
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta property="og:url" content="https://safeticha.com" />
        <link rel="canonical" href="https://safeticha.com" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="robots" content="index, nofollow" />
        <meta name="apple-mobile-web-app-title" content="Safeticha" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="#0D41E1" name="theme-color" />
        <meta content="#0D41E1" name="msapplication-TileColor" />
        <link rel="stylesheet" href="/styles/nprogress.css" />

        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto"
          rel="stylesheet"
        />
        <title>
          Safeticha - Safe recruitment for schools, professional development for
          educators.
        </title>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/find-courses" element={<Courses />} />
          <Route path="/buy-course/:courseId" element={<BuyCourse />} />
          <Route path="/summer-fest" element={<SummerFest />} />
          <Route path="/marketplace" element={<MarketPlace />} />
          <Route path="/gift-a-teacher" element={<GiftATeacher />} />
          <Route path="/store/:storePath" element={auth ? <PrivateRoute><MarketPlace /></PrivateRoute> : <MarketPlace />} />
          <Route path="/store/:storePath/:resourceId" element={auth ? <PrivateRoute><ResourceDetails /></PrivateRoute> :<ResourceDetails />} />
          <Route path="/resource/marketplace" element={<PrivateRoute><MarketPlace /></PrivateRoute>} />
         
          <Route path="/resource-more" element={<ResourcesLandingPage />} />
          {/* <Route path="/resources-marketplace" element={auth ? <PrivateRoute><Resources /></PrivateRoute> :<Resources />} /> */}
          
          <Route path="/marketplace/:resourceId" element={auth ? <PrivateRoute><ResourceDetails /></PrivateRoute> :<ResourceDetails />} />
          <Route path="/jobs/view/:code" element={<JobView />} />
          {/* login */}
          <Route path={PAGES.login} element={<Login />} />
          <Route path={PAGES.forgotPassword} element={<ForgotPassword />} />
          <Route
            path={PAGES.emailVerification}
            element={<LoginVerifyEmail />}
          />
          <Route path={PAGES.resetPassword} element={<ResetPassword />} />

          {/* sign up */}
          <Route path={PAGES.signup} element={<Signup forceChangePassword={false} />} />
          <Route path={"/signup_alt"} element={<Signup forceChangePassword={true} />} />
          <Route
            path={PAGES.signupVerification}
            element={<SignupVerification />}
          />

          <Route
            path={PAGES.dashboard}
            element={
              <PrivateRoute>
                <DashboardHome />
              </PrivateRoute>
            }
          />
          {/* dashboard */}

          {/* courses */}
          <Route
            path={PAGES.courses}
            element={
              <PrivateRoute>
                <DashboardCourses />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.courses + "/:courseId"}
            element={
              <PrivateRoute>
                <DashboardCourseDetails />
              </PrivateRoute>
            }
          />

          {/* my courses */}
          <Route
            path={PAGES.myCourses}
            element={
              <PrivateRoute>
                <DashboardMyCourses />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.myCourses + "/:courseId"}
            element={
              <PrivateRoute>
                <DashboardWatchCourse />
              </PrivateRoute>
            }
          />

          <Route
            path={PAGES.question + "/:courseId/module/:module"}
            element={<ModuleQuestion />}
          />

          <Route
            path={PAGES.teachers}
            element={
              <PrivateRoute>
                <DashboardTeachers />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.profile}
            element={
              <PrivateRoute>
                <DashboardProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.documents}
            element={
              <PrivateRoute>
                <Documents />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.resources}
            element={
              <PrivateRoute>
                <ResourceList />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.comingSoon}
            element={
              <PrivateRoute>
                <ComingSoon />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.addResource}
            element={
              <PrivateRoute>
                <AddResource />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.editResource}
            element={
              <PrivateRoute>
                <EditResource />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.messages}
            element={
              <PrivateRoute>
                <DashboardMessages />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.jobs}
            element={
              <PrivateRoute>
                <DashboardJobs />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.jobs + "/create"}
            element={
              <PrivateRoute>
                <AddJob />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.jobs + "/edit/:jobId"}
            element={
              <PrivateRoute>
                <AddJob isEdit />
              </PrivateRoute>
            }
          />

          <Route
            path={PAGES.jobs + "/:jobId"}
            element={
              <PrivateRoute>
                <JobDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.jobs + "/view-application/:applicationId"}
            element={
              <PrivateRoute>
                <ViewApplication />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.help}
            element={
              <PrivateRoute>
                <DashboardHelp />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.settings}
            element={
              <PrivateRoute>
                <DashboardSettings />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.cart}
            element={ auth ?
              <PrivateRoute>
                <DashboardCart />
              </PrivateRoute> : <>
              <Box padding="0px 35px 0px 15px"><Navbar /></Box>
              <Cart btnText="Go Back" />
              </>
            }
          />
          <Route
            path={PAGES.notifications}
            element={
              <PrivateRoute>
                <DashboardNotifications />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.manageCourse + "/:courseId"}
            element={
              <PrivateRoute>
                <DashboardManageCourse />
              </PrivateRoute>
            }
          />
          <Route
            path={PAGES.certificate + "/:courseId"}
            element={
              <PrivateRoute>
                <ViewCertificate />
              </PrivateRoute>
            }
          />

          <Route
            path={PAGES.changePassword}
            element={<PrivateRoute>
              <ChangeProfilePassword />
            </PrivateRoute>}
          />

          <Route
            path={PAGES.checkout}
            element={
              <PrivateRoute>
                <CartCheckout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <CssBaseline />
      <AppBase />
      <ToastContainer />
    </Provider>
  );
}

// <Route
// path={PAGES.dashboard}
// element={
//   <PrivateRoute>
//     <Dashboard />
//   </PrivateRoute>
// }
// >

// </Route>
